import { getUrlFriendly } from '@portal-internet/core';
import clsx from 'clsx';
import Enllac from 'components/3cat/Enllac';
import Boto from 'components/ui/Boto';
import { useProducte } from 'hooks/3cat/useProducte';
import __isEmpty from 'lodash/isEmpty';
import { useRouter } from 'next/router';
import { useRef, useState } from 'react';
import Icona from '../ui/Icona';
import Modal from './Modal';
import styles from './compartir.module.scss';

export default function Compartir(props) {
    const { className, mida, titol, mostraTwitter, mostraWhatsapp, mostraFacebook, mostraEmbed, route, tipus } = props;

    const { isWebviewActive } = useProducte();

    const [showModal, setShowModal] = useState(false);

    const openModalHanlder = () => {
        setShowModal(true);
    };

    const closeModalHanlder = () => {
        setShowModal(false);
    };

    const [textCopia, setTextCopia] = useState('Copia');

    const inputCompartir = useRef();

    const stylesCompartir = clsx({
        [styles.compartir]: true,
        [className]: !__isEmpty(className)
    });

    const router = useRouter();

    const _canviaText = () => {
        setTextCopia('Copiat!');
        setTimeout(() => {
            setTextCopia('Copia');
        }, 1000);
    };

    const _getCanal = (canals) => {
        const c = canals.canal ? canals.canal : canals;
        const canalMain = c.find((canal) => canal.main === true);
        return canalMain?.desc || c?.[0]?.desc || 'sense';
    };

    const _getUrl = (route) => {
        const canalMain = route?.canals ? _getCanal(route.canals) : 'sense';
        return getUrlFriendly({ ...route, routePath: route?.type, canal: canalMain, producte: 'web' });
    };

    const slug = !__isEmpty(route) ? route.slug : titol;
    const _getUrlShare = () => {
        const cleanUrl = url.split('?');
        return cleanUrl[0] + '?type=share&t=' + titol + '&ext=APP_APP3C_F4_3CAT_CAPP_';
    };

    const url = __isEmpty(route) ? 'https://www.3cat.cat' + router.asPath : 'https://www.3cat.cat' + _getUrl(route);

    const urlShare = isWebviewActive ? _getUrlShare() : url;

    const hitAudiencies = (type) => {
        const xarxa = type || 'XS desconeguda';
        _satellite?.track('scCompartir', { xarxaAccioContingut: xarxa + slug });
    };
    if (isWebviewActive) {
        return (
            <div
                className={stylesCompartir}
                onClick={() => {
                    hitAudiencies('XS desconeguda:share:');
                }}
            >
                <Enllac className={styles.boto} href={urlShare}>
                    <Icona inline={true} mida={mida} className={styles.icona} icona='compartir' alt='Compartir' />
                </Enllac>
            </div>
        );
    } else {
        return (
            <div className={stylesCompartir}>
                <button className={styles.boto} onClick={openModalHanlder} aria-label='Comparteix'>
                    <Icona inline={true} mida={mida} className={styles.icona} icona='compartir' alt='Compartir' />
                </button>

                <Modal isOpen={showModal}>
                    <div className={styles.mask} onClick={closeModalHanlder}></div>
                    <div className={styles.overlay}>
                        <h2 className={styles.titol}>Comparteix</h2>
                        <button aria-label='Tanca' className={styles.btn_tanca} onClick={closeModalHanlder}>
                            <Icona className={styles.ico_tanca} inline={true} icona='tanca' />
                        </button>
                        <div className={styles.links}>
                            {mostraWhatsapp && (
                                <div
                                    onClick={() => {
                                        hitAudiencies('Whatsapp:share:');
                                    }}
                                >
                                    <Enllac
                                        href={'https://wa.me/?text=' + urlShare + '?ext=SM_WP_F4_CE24_'}
                                        className={styles.link}
                                        data-action='share/whatsapp/share'
                                        target='_blank'
                                    >
                                        <Icona
                                            icona='whatsappGran'
                                            inline={true}
                                            className={styles.iconaSocial}
                                            alt='Icona Whatsapp'
                                        />
                                        Whatsapp
                                    </Enllac>
                                </div>
                            )}
                            {mostraFacebook && (
                                <button
                                    onClick={() => {
                                        const cadena =
                                            'https://www.facebook.com/sharer/sharer.php?u=' + urlShare + '?ext=SM_FB_F4_CE24_';
                                        window.open(cadena, 'Facebook', 'toolbar=0, status=0, width=550, height=500');
                                        hitAudiencies('Facebook:share:');
                                    }}
                                    className={styles.link}
                                >
                                    <Icona
                                        icona='facebookGran'
                                        inline={true}
                                        className={styles.iconaSocial}
                                        alt='Icona Facebook'
                                    />
                                    Facebook
                                </button>
                            )}
                            {mostraTwitter && (
                                <button
                                    onClick={() => {
                                        const cadena =
                                            'https://twitter.com/intent/tweet?text=' +
                                            titol +
                                            '&url=' +
                                            urlShare +
                                            '?ext=SM_TW_F4_CE24_';
                                        window.open(cadena, 'Twitter', 'toolbar=0, status=0, width=550, height=500');
                                        hitAudiencies('Twitter:share:');
                                    }}
                                    href={'https://twitter.com/intent/tweet?text=' + titol + '&url=' + url}
                                    className={styles.link}
                                >
                                    <Icona icona='x' inline={true} className={styles.iconaSocial} alt='Icona X' />X
                                </button>
                            )}
                        </div>
                        {mostraEmbed && (
                            <div className={styles.embed} data-testid='input-embed'>
                                <input
                                    type='text'
                                    className={styles.input}
                                    readOnly
                                    ref={inputCompartir}
                                    onClick={() => {
                                        inputCompartir.current.setSelectionRange(0, 9999);
                                    }}
                                    value={
                                        '<iframe title="' +
                                        tipus +
                                        ' ' +
                                        router.query.id +
                                        '" src="https://www.3cat.cat/3cat/' +
                                        tipus +
                                        '/' +
                                        router.query.id +
                                        '/embed/" allowfullscreen scrolling="no" frameborder="0" width="500px" height="281px"></iframe>'
                                    }
                                />
                                <Boto
                                    tag='button'
                                    text={textCopia}
                                    estil='transparent'
                                    className={styles.btn_copia}
                                    onClick={() => {
                                        navigator.clipboard.writeText(inputCompartir.current.value);
                                        _canviaText();
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </Modal>
            </div>
        );
    }
}

Compartir.defaultProps = {
    mida: '20',
    titol: '',
    tipus: 'video',
    mostraTwitter: true,
    mostraWhatsapp: true,
    mostraFacebook: true,
    mostraEmbed: false,
    isWebviewActive: false
};
